import React from 'react';

const IconPluralsight = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.0"
    stroke="currentColor"
    width="260.000000pt"
    height="260.000000pt"
    viewBox="0 0 260.000000 260.000000"
    preserveAspectRatio="xMidYMid meet">
    <metadata>Created by potrace 1.11, written by Peter Selinger 2001-2013</metadata>
    <g transform="translate(0.000000,260.000000) scale(0.100000,-0.100000)" stroke="none">
      <path d="M1169 2596 c-2 -2 -24 -6 -49 -10 -90 -12 -117 -18 -207 -46 -349 -111 -628 -353 -788 -686 -36 -74 -90 -233 -100 -294 -3 -19 -9 -55 -13 -80 -14 -92 -7 -338 12 -430 20 -92 28 -127 36 -145 4 -11 22 -56 39 -100 35 -90 112 -223 173 -300 202 -254 460 -414 778 -482 130 -27 382 -25 519 6 451 100 812 418 958 846 29 83 36 113 62 250 10 50 8 312 -2 372 -93 541 -484 955 -1013 1073 -70 16 -143 24 -250 26 -82 3 -152 3 -155 0z m368 -140 c368 -80 667 -316 828 -651 33 -70 70 -169 79 -216 2 -13 10 -46 16 -74 16 -68 22 -284 10 -370 -73 -507 -437 -894 -944 -1002 -101 -22 -341 -24 -441 -4 -426 84 -771 386 -903 791 -40 122 -53 195 -58 330 -7 205 29 375 116 555 170 350 508 596 897 654 85 13 317 5 400 -13z" />
      <path d="M968 1821 l-3 -149 -125 74 c-69 40 -128 73 -132 74 -5 0 -8 -234 -8 -520 0 -286 3 -520 8 -520 4 1 63 34 132 74 l125 74 5 -149 5 -148 70 41 c156 90 858 496 968 559 l119 67 -99 57 c-54 32 -118 70 -143 85 -37 22 -219 128 -430 248 -29 16 -298 172 -447 258 l-42 25 -3 -150z m932 -521 c0 -3 -26 -20 -57 -38 -121 -68 -230 -131 -336 -194 -26 -15 -49 -28 -51 -28 -2 0 -45 -25 -95 -55 -51 -30 -93 -55 -95 -55 -2 0 -41 -22 -87 -50 -46 -27 -87 -50 -91 -50 -5 0 -8 37 -8 83 l1 82 259 149 c143 83 260 153 260 156 0 5 -313 190 -477 281 l-43 24 0 87 0 86 410 -236 c225 -131 410 -239 410 -242z m-1004 282 l71 -41 1 -240 0 -241 -56 -34 c-32 -18 -67 -39 -79 -45 l-23 -11 1 330 c0 182 3 329 7 327 4 -2 39 -23 78 -45z m312 -179 c64 -36 130 -75 145 -86 l28 -19 -138 -80 c-76 -44 -144 -83 -150 -85 -10 -4 -13 33 -13 166 0 94 2 171 5 171 3 0 58 -30 123 -67z" />
    </g>
    <auto-scroll xmlns="http://www.w3.org/1999/xhtml"></auto-scroll>
  </svg>
);

export default IconPluralsight;
